<template>
  <div>
    <div>
      <b-modal
        size="lg"
        v-model="isModalOpen"
        @hidden="hideModal"
        hide-footer
        title="Thông tin đơn đặt hàng"
      >
        <b-table
          :items="purchaseOrdersSamsung"
          :fields="fields"
          striped
          hover
        >
          <template v-slot:cell(requestedDeliveryToDate)="row">
            <date-picker
              placeholder="Nhập ngày yêu cầu nhập hàng đến"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="row.item.requestedDeliveryToDate"
            ></date-picker>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              @click="deletePurchaseOrder(row.item)"
              class="btn btn-danger"
            ><i class="pr-0 fa fa-trash-alt"></i></b-button>
          </template>
        </b-table>
        <b-button
          class="mr-2"
          @click="submit()"
          variant="primary"
        >Xác nhận</b-button>
        <b-button
          @click="hideModal()"
          variant="secondary"
        >Đóng</b-button>
      </b-modal>
    </div>
    <div>
      <b-modal
        size="lg"
        v-model="isModalOpenError"
        @hidden="hideModalError"
        hide-footer
        title="Danh sách lỗi!"
      >
        <b-table
          :items="errors"
          :fields="errFields"
          :per-page="perPage"
          :current-page="currentPage"
          striped
          hover
        >
          <template v-slot:cell(id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(barCode)="row">
            {{ row.item.barCode }}
          </template>
          <template v-slot:cell(productBrandCode)="row">
            <span v-if="row.item.productBrandCode">{{ row.item.productBrandCode }}</span>
            <i
              v-else
              class="fas fa-times-circle text-danger"
              variant="danger"
              v-b-tooltip.hover
              title="Không tìm thấy mã hãng"
            ></i>
          </template>
          <template v-slot:cell(alias)="row">
            <span v-if="row.item.alias">{{ row.item.alias }}</span>
            <i
              v-else
              class="fas fa-times-circle text-danger"
              variant="danger"
              v-b-tooltip.hover
              title="Không tìm thấy alias"
            ></i>
          </template>
        </b-table>
        <b-button
          @click="hideModalError()"
          variant="secondary"
        >Đóng</b-button>
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ errors.length }}
            </p>
          </b-col>
          <b-col>
            <b-pagination
              v-if="errors.length > perPage"
              class="custom-pagination align-items-end"
              v-model="currentPage"
              :total-rows="errors.length"
              :per-page="perPage"
              aria-controls="my-table"
              align="right"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>
  
<script>
import ApiService from '@/core/services/api.service';
import { DP_CONFIG, YEAR_MONTH_DAY_FORMAT } from '@/utils/date';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  props: {
    modalOpen: {
      type: Boolean,
      required: true,
    },
    selectedPurchaseOrders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
      dpConfigs: DP_CONFIG,
      purchaseOrdersSamsung: [],
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'requestedDeliveryToDate',
          label: 'Ngày yêu cầu nhận hàng',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      errFields: [
        {
          key: 'index',
          label: 'STT',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'barCode',
          label: 'Barcode',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'productBrandCode',
          label: 'Mã hãng',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'alias',
          label: 'Alias',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      isModalOpenError: false,
      errors: [],
      currentPage: 1,
      perPage: 10,
    };
  },
  created() {
    this.dpConfigs.date.format = YEAR_MONTH_DAY_FORMAT;
  },
  methods: {
    async submit() {
      if (!this.purchaseOrdersSamsung.length) {
        return makeToastFaile('Vui lòng chọn phiếu đặt hàng để gửi!');
      }
      const findNotDateExist = this.purchaseOrdersSamsung.find(
        (item) => !item.requestedDeliveryToDate,
      );
      if (findNotDateExist) {
        return makeToastFaile('Vui lòng nhập ngày nhận hàng!');
      }
      const body = {
        purchaseOrders: this.purchaseOrdersSamsung,
      };
      try {
        const response = await ApiService.post(
          'purchase-order/send-purchase-order',
          body,
        );
        const { message } = response.data;
        this.$emit('fetch-data');
        makeToastSuccess(message);
      } catch (err) {
        const { message } = err.response.data;
        if (message && Array.isArray(message)) {
          this.errors = message || [];
          this.hideModal();
          this.isModalOpenError = true;
        } else {
          return makeToastFaile(message || 'Lỗi');
        }
      }
      this.hideModal();
    },
    hideModal() {
      this.$emit('modal-hidden');
    },
    hideModalError() {
      this.isModalOpenError = false;
    },
    deletePurchaseOrder(PO) {
      this.purchaseOrdersSamsung = this.purchaseOrdersSamsung.filter(
        (element) => {
          return PO.id !== element.id;
        },
      );
    },
  },
  watch: {
    modalOpen(value) {
      this.isModalOpen = value;
    },
    selectedPurchaseOrders(purchaseOrdersSamsung) {
      this.purchaseOrdersSamsung = purchaseOrdersSamsung;
    },
  },
};
</script>